// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .idb-permissions-exceptionas-table {
  max-height: 20rem !important;
}
::ng-deep .idb-permissions-exceptionas-table .idb-table-actions {
  min-width: 3.5rem !important;
  width: 3.5rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user-context-role-scope-profile/user-context-role-scope-profile-denied-confirm.component.scss"],"names":[],"mappings":"AACI;EACI,4BAAA;AAAR;AAEQ;EACI,4BAAA;EACA,wBAAA;AAAZ","sourcesContent":["::ng-deep {\r\n    .idb-permissions-exceptionas-table {\r\n        max-height: 20rem !important;\r\n\r\n        .idb-table-actions {\r\n            min-width: 3.5rem !important;\r\n            width: 3.5rem !important;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
