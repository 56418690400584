import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserContextRoleScopeProfileApprovedComponent } from './user-context-role-scope-profile-approved.component';
import { UserContextRoleScopeProfileDeniedComponent } from './user-context-role-scope-profile-denied.component';
import { UserContextRoleScopeProfilePendingComponent } from './user-context-role-scope-profile-pending.component';

export function showProfileDetails(
    dialogSrv: DialogService,
    profileStatus: string,
    data: any,
    onAccept: (deactivationReason: string | undefined) => void = () => {},
    onCancel: () => void = () => {}
) {
    let editScopeRef: DynamicDialogRef | undefined;

    const options: DynamicDialogConfig = {
        appendTo: 'body',
        closeOnEscape: false,
        showHeader: false,
        modal: true,
        contentStyle: { overflow: 'hidden' },
        data: {
            ...data,
            doAccept: (deactivationReason: string | undefined) => {
                editScopeRef?.close();
                onAccept(deactivationReason);
            },
            doCancel: () => {
                editScopeRef?.close();
                onCancel();
            },
        },
    };

    if (profileStatus.startsWith('approved') || profileStatus.startsWith('recertify')) {
        options.width = '70%';
        options.height = '60rem';
        editScopeRef = dialogSrv.open(UserContextRoleScopeProfileApprovedComponent, options);
    } else if (profileStatus.startsWith('denied')) {
        options.width = '60rem';
        options.height = '18rem';
        editScopeRef = dialogSrv.open(UserContextRoleScopeProfileDeniedComponent, options);
    } else if (profileStatus.startsWith('pending')) {
        options.width = '60rem';
        options.height = '21rem';
        editScopeRef = dialogSrv.open(UserContextRoleScopeProfilePendingComponent, options);
    }
}
