import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import { DialogService } from 'primeng/dynamicdialog';

import { clone, cloneDeep } from 'lodash-es';

import {
    ActionDef,
    ActionItemDef,
    AnyKey,
    EditorBaseComponent,
    ChangedEvent,
    ContextRoleService,
    MetaService,
    OptionDTO,
    ReadContextRoleScopeProfilesRequestDTO,
    ReadMetaRequestDTO,
    TypeScopeDTO,
    UnknownErrorCallingAPIMessage,
    UserAccountInformationDTO,
    UserContextRoleDTO,
    UserContextRoleScopeDTO,
    UserContextRoleScopeFieldDTO,
    doReadContextRoleScope,
    firstOrDefault,
    generateScopes,
    isAssigned,
    moveFieldToFilter,
    updateFields,
    GlobalsService,
} from '../../data';
import { ContextRoleScopePickerComponent, ContextRoleScopePickerKind } from '../context-role-scope-picker/context-role-scope-picker.component';

export interface ContextRoleChangedEvent extends ChangedEvent {
    contextCd: string;
    roleCd: string;
    preferedLanguageCd: string;
    grantedFrom?: Date;
    grantedTo?: Date;
    scopeFields: UserContextRoleScopeFieldDTO[];
    existingScopes: UserContextRoleScopeDTO[];
    newScopes: UserContextRoleScopeDTO[];
}

const ComponentName = 'user-context-role-details-editor';

@Component({
    selector: `app-${ComponentName}`,
    templateUrl: `./user-context-role-details-editor.component.html`,
    styleUrls: [`./user-context-role-details-editor.component.scss`],
})
export class UserContextRoleDetailsEditorComponent extends EditorBaseComponent {
    isLoadingScopesProfiles = false;
    isLoadingScopes = false;

    existingScopeItemActions: ActionItemDef[] = [
        {
            getIcon: (element) => (element.isDeleted === true ? 'pi pi-undo' : 'pi pi-times'),
            styleClass: 'danger',
            tooltip: (element) => (element.isDeleted === true ? this.scopesDeleteUndoButton : this.scopesDeleteButton),
            isVisible: (element) => element?.canDeleteScope === true,
            run: (element) => {
                element.isDeleted = element.isDeleted === true ? false : true;
                this.emitOnChanged();
            },
        },
    ];
    newScopeItemActions: ActionItemDef[] = [
        {
            getIcon: (element) => (element.isDeleted === true ? 'pi pi-undo' : 'pi pi-times'),
            styleClass: 'danger',
            tooltip: (element) => (element.isDeleted === true ? this.scopesDeleteUndoButton : this.scopesDeleteButton),
            isVisible: (element) => element?.canDeleteScope === true,
            run: (element) => this.onRemoveNewScope(element),
        },
    ];
    newScopeFooterActions: ActionDef[] = [
        {
            icon: 'pi pi-plus',
            styleClass: 'success',
            tooltip: () => this.newScopesAddButton,
            isVisible: () => this.isNewRole || (this.contextRole.information?.canAddScope === true && this.contextRole.information?.hasScope === true),
            run: () => {
                this.onAddScope();
            },
        },
    ];

    existingScopes: UserContextRoleScopeDTO[] = [];
    newScopes: UserContextRoleScopeDTO[] = [];
    contextRoleCd: string | undefined;
    showCanCreateAccounts = false;
    showCanReceiveApprovalNotifications = false;

    @Input()
    isNewUser = false;

    @Input()
    isNewRole = false;

    @Input()
    canShowPreferredLanguage = true;

    @Input()
    accountInfo: UserAccountInformationDTO = {};

    private _contextRoleO: UserContextRoleDTO = { userRoleContextId: -1 };
    private _contextRole: UserContextRoleDTO = { userRoleContextId: -1 };
    @Input()
    set contextRole(cr: UserContextRoleDTO | undefined) {
        this._contextRole = cr ?? { userRoleContextId: -1 };
        this._scopeFields = this._contextRole.scopeFields ?? [];
        this.existingScopes = cloneDeep(this._contextRole.scopes?.filter((s) => s.canDeleteScope === true) ?? []);
        this.newScopes = [];

        if (isAssigned(this._contextRole.information)) {
            this._contextRole.information!.preferredLanguageCd = this._contextRole.information?.preferredLanguageCd ?? this.accountInfo.preferredLanguageCd;
            this._contextRole.information!.canCreateAccounts = this._contextRole.information!.canCreateAccounts === true;
            this._contextRole.information!.canReceiveApprovalNotifications = this._contextRole.information!.canReceiveApprovalNotifications === true;
        } else {
            this._contextRole.information = {
                userRoleContextId: this._contextRole.userRoleContextId,
                preferredLanguageCd: this.accountInfo.preferredLanguageCd,
            };
        }

        this._contextRole.information!.preferredLanguageCd = this._contextRole.information!.preferredLanguageCd?.toLowerCase();

        this._contextRoleO = cloneDeep(this._contextRole);

        this.existingScopes.forEach((s) => {
            s.canEditScope = false;
        });

        this.readRoleDetails();

        if (isAssigned(this._contextRole.contextCd) && isAssigned(this._contextRole.roleCd)) {
            this.readScopes();
        }

        this.emitOnChanged();
    }
    get contextRole(): UserContextRoleDTO {
        return this._contextRole;
    }

    private _scopeFields: UserContextRoleScopeFieldDTO[] = [];
    get scopeFields(): UserContextRoleScopeFieldDTO[] {
        return this._scopeFields;
    }

    private _newScopeFields: UserContextRoleScopeFieldDTO[] = [];
    get newScopeFields(): UserContextRoleScopeFieldDTO[] {
        return this._newScopeFields;
    }

    constructor(
        private readonly _dialogSrv: DialogService,
        private readonly _contextRoleSrv: ContextRoleService,
        private readonly _globalsSrv: GlobalsService,
        translateSrv: TranslateService,
        toastr: ToastrService,
        metaSrv: MetaService,
        location: Location
    ) {
        super(translateSrv, toastr, metaSrv, location);

        this._translateKey = ComponentName;
    }

    protected override fillReadMetaRequest(request: ReadMetaRequestDTO): void {
        request.contextCd = this._contextRole.contextCd;
        request.roleCd = this._contextRole.roleCd;
    }

    get existingScopesDeleteLabel(): string {
        if (this.accountInfo.isInternal === true) {
            return this.translate('internal.delete.header');
        }

        return this.translate('external.delete.header');
    }

    get existingScopesDeleteWarning(): string {
        if (this.accountInfo.isInternal === true) {
            return this.translate('internal.delete.warning');
        }

        return this.translate('external.delete.warning');
    }

    get newScopesAddLabel(): string {
        if (this.accountInfo.isInternal === true) {
            if (this.contextRole.information?.hasScope === true) {
                return this.translate('internal.addScope.header');
            } else {
                return this.translate('internal.add.header');
            }
        }

        if (this.contextRole.information?.hasScope === true) {
            return this.translate('external.addScope.header');
        } else {
            return this.translate('external.add.header');
        }
    }

    get newScopesAddWarning(): string {
        if (this.accountInfo.isInternal === true) {
            return this.translate('internal.add.warning');
        }

        return this.translate('external.add.warning');
    }

    get newScopesAddButton(): string {
        if (this.accountInfo.isInternal === true) {
            return this.translate('internal.addScope.button');
        }

        return this.translate('external.addScope.button');
    }

    get scopesDeleteButton(): string {
        if (this.accountInfo.isInternal === true) {
            return this.translate('internal.delete.button');
        }

        return this.translate('external.delete.button');
    }

    get scopesDeleteUndoButton(): string {
        if (this.accountInfo.isInternal === true) {
            return this.translate('internal.delete.undoButton');
        }

        return this.translate('external.delete.undoButton');
    }

    get contextRoleCds(): OptionDTO[] {
        let list = this.metaOptions['availableContextRoles'];

        if ((list?.length ?? 0) < 1) {
            list = this.metaOptions['existingContextRoles'];

            if ((list?.length ?? 0) < 1) {
                list = [];
            }
        }

        return list;
    }

    get contextRoleCdFilter(): boolean {
        return this.contextRoleCds.length > 15;
    }

    get languages(): OptionDTO[] {
        return this.metaOptions['language'] ?? [{ val: 'en', label: 'English' }];
    }

    get isExistingScopesVisible(): boolean {
        return !this.isNewRole && this.isContextRoleCdValid && this.contextRole.information?.hasScope === true && this.existingScopes.length > 0;
    }

    get isNewScopesVisible(): boolean {
        return this.isContextRoleCdValid;
    }

    override get isLoadingInternal(): boolean {
        return super.isLoadingInternal || this.isLoadingScopesProfiles || this.isLoadingScopes;
    }

    get isContextRoleValid(): boolean {
        return this.isNewRole ? isAssigned(this.contextRoleCd) && this.contextRoleCd !== '' : true;
    }

    get isRoleGrantedDatesValid(): boolean {
        if (isAssigned(this._contextRole.information?.roleGrantedFromDate) && isAssigned(this._contextRole.information?.roleGrantedToDate)) {
            return this._contextRole.information?.roleGrantedFromDate! < this._contextRole.information?.roleGrantedToDate!;
        }

        return true;
    }

    get isPreferredLanguageValid(): boolean {
        return (
            this.canShowPreferredLanguage &&
            isAssigned(this._contextRole.information?.preferredLanguageCd) &&
            this._contextRole.information?.preferredLanguageCd !== ''
        );
    }

    get isNewScopesValid(): boolean {
        if (this.isNewRole || this.isNewUser) {
            const approvedScopes = this.newScopes.filter(
                (s) =>
                    this.newScopeFields.length > 0 &&
                    isAssigned(
                        this.newScopeFields.find((sf) => {
                            if (sf.fieldType?.toLowerCase() === 'switch') {
                                const sfkn = `${sf.fieldMapping}Value` as AnyKey;
                                const val = (s as any)[sfkn]?.toUpperCase() ?? '';

                                return val.startsWith('APPROVED');
                            }
                            return false;
                        })
                    )
            );

            return this.newScopes.length > 0 && this.newScopes.length === approvedScopes.length;
        }

        return true;
    }

    override get isValid(): boolean {
        return super.isValid && this.isContextRoleValid && this.isPreferredLanguageValid && this.isRoleGrantedDatesValid && this.isNewScopesValid;
    }

    get hasScopesChanged(): boolean {
        let res = false;

        let schanged = this.existingScopes.filter((s) => s.hasChanged === true || s.isDeleted === true);

        if (schanged.length === 0) {
            schanged = this.newScopes.filter((s) => s.hasChanged === true || s.isDeleted === true);
        }

        return schanged.length > 0;
    }

    override get hasChanged(): boolean {
        if (super.hasChanged) {
            return true;
        }

        if (this.isNewRole && isAssigned(this.contextRoleCd) && this.contextRoleCd !== '') {
            return true;
        }

        if (
            this._contextRole.information?.roleGrantedFromDate !== this._contextRoleO.information?.roleGrantedFromDate ||
            this._contextRole.information?.roleGrantedToDate !== this._contextRoleO.information?.roleGrantedToDate
        ) {
            return true;
        }

        if (
            this.showCanCreateAccounts &&
            (this.isNewUser || this.isNewRole || this._contextRole.information?.canCreateAccounts !== this._contextRoleO.information?.canCreateAccounts)
        ) {
            return true;
        }

        if (
            this.showCanReceiveApprovalNotifications &&
            (this.isNewUser ||
                this.isNewRole ||
                this._contextRole.information?.canReceiveApprovalNotifications !== this._contextRoleO.information?.canReceiveApprovalNotifications)
        ) {
            return true;
        }

        return this.hasScopesChanged || this._contextRole.information?.preferredLanguageCd !== this._contextRoleO.information?.preferredLanguageCd;
    }

    get isContextRoleCdVisible(): boolean {
        return this.isNewRole;
    }

    get isContextRoleCdValid(): boolean {
        return this.isContextRoleCdVisible ? isAssigned(this.contextRoleCd) && this.contextRoleCd !== ',' : true;
    }

    protected override get changedEvent(): ChangedEvent {
        const $e: ContextRoleChangedEvent = {
            hasChanged: this.hasChanged,
            isValidated: this.isValid,
            contextCd: this._contextRole.contextCd ?? this._contextRole.information?.contextCd ?? '',
            roleCd: this._contextRole.roleCd ?? this._contextRole.information?.roleCd ?? '',
            preferedLanguageCd: this._contextRole.information?.preferredLanguageCd ?? '',
            grantedFrom: this._contextRole.information?.roleGrantedFromDate,
            grantedTo: this._contextRole.information?.roleGrantedToDate,
            scopeFields: this._scopeFields,
            existingScopes: this.existingScopes,
            newScopes: this.newScopes,
        };

        return $e;
    }

    private translateNewScopes() {
        this.newScopes.forEach((s) => {
            this.newScopeFields
                .filter((sf) => sf.fieldType?.toLowerCase() === 'switch')
                .forEach((sf) => {
                    const sfvk = `${sf.fieldMapping}Value` as AnyKey;
                    const sflk = sf.fieldMapping as AnyKey;
                    const val = (((s as any)[sfvk] as string) ?? '').toLowerCase();

                    if (!this.isEmpty(val)) {
                        const trn = this.translateDirect(`profile.status.${val}`);

                        (s as any)[sflk] = trn.toUpperCase();
                    }
                });
        });
    }

    private readScopesProfiles(scopes?: TypeScopeDTO[]) {
        if (this.isLoadingScopesProfiles) {
            return;
        }

        this.isLoadingScopesProfiles = true;
        this.emitOnLoading();

        const request: ReadContextRoleScopeProfilesRequestDTO = {
            languageCd: this.translateSrv.currentLang,
            scope: moveFieldToFilter(scopes) ?? [],
        };

        if (isAssigned(this._contextRole.contextCd)) {
            request.contextCd = this._contextRole.contextCd;
        }
        if (isAssigned(this._contextRole.roleCd)) {
            request.roleCd = this._contextRole.roleCd;
        }

        this._contextRoleSrv.readContextRoleScopeProfiles(request).subscribe({
            next: (response) => {
                this.isLoadingScopesProfiles = false;
                this.emitOnLoading();

                if (!this.showErrorMessage(response)) {
                    updateFields(this._contextRole, this._newScopeFields, response.scopeFields ?? [], this.newScopes, response.scopes ?? []);
                    this.translateNewScopes();
                    this._newScopeFields = clone(this._contextRole.scopeFields ?? []);
                    this.emitOnChanged();
                }
            },
            error: (error) => {
                this.isLoadingScopesProfiles = false;
                this.emitOnLoading();

                this.toastr.error(error.message ?? UnknownErrorCallingAPIMessage);
            },
        });
    }

    private readScopes(scopes?: TypeScopeDTO[]) {
        if (this.isLoadingScopes) {
            return;
        }

        this._newScopeFields = [];

        doReadContextRoleScope(
            this.toastr,
            this._globalsSrv,
            this._contextRoleSrv,
            (isLoading) => {
                this.isLoadingScopes = isLoading;
                this.emitOnLoading();
            },
            (response) => {
                updateFields(this._contextRole, this._newScopeFields, response.scopeFields ?? [], this.newScopes, response.scopes ?? []);
                this._newScopeFields = this._contextRole.scopeFields ?? [];
                this.emitOnChanged();
            },
            this._contextRole,
            scopes
        );
    }

    onRemoveNewScope(scope: UserContextRoleScopeDTO) {
        const ind = this.newScopes.indexOf(scope);
        if (ind > -1) {
            this.newScopes.splice(ind, 1);
            if (this.newScopes.length === 0) {
                this.readScopes();
            }
            this._newScopeFields = clone(this._newScopeFields);
            this.emitOnChanged();
        }
    }

    onAddScope() {
        const existingScope = generateScopes(this._contextRole, this.existingScopes, this.newScopes);

        let editScopeRef = this._dialogSrv.open(ContextRoleScopePickerComponent, {
            showHeader: false,
            width: '50%',
            height: '60rem',
            contentStyle: { overflow: 'hidden' },
            data: {
                pickerKind: this.accountInfo.isInternal === true ? ContextRoleScopePickerKind.Scope : ContextRoleScopePickerKind.Affiliation,
                accountInfo: this.accountInfo,
                userRoleContextId: this._contextRole.userRoleContextId > -1 ? this._contextRole.userRoleContextId : undefined,
                contextCd: this._contextRole.contextCd ?? this._contextRole.information?.contextCd ?? '',
                roleCd: this._contextRole.roleCd ?? this._contextRole.information?.roleCd ?? '',
                existingScope: existingScope,
                usage: 'scope',
                doClose: (result?: any) => editScopeRef?.close(result),
            },
        });

        editScopeRef.onClose.subscribe((result?: any[]) => {
            this.onAddScopeResults(result);
        });
    }

    onAddScopeResults(result?: TypeScopeDTO[]) {
        if (isAssigned(result) && (result?.length ?? 0) > 0) {
            result?.forEach((s) => {
                s.isActive = true;
                s.isException = undefined;
            });

            this.readScopesProfiles(result);
        }
    }

    private readRoleDetails() {
        if (isAssigned(this._contextRole.contextCd) && isAssigned(this._contextRole.roleCd)) {
            this.readMeta(
                (metaOptions) => {
                    const roleDetail = metaOptions['roleDetail'] ?? [];
                    this.showCanCreateAccounts = isAssigned(firstOrDefault(roleDetail.filter((rd) => 'cancreateaccounts' === rd.val.toLowerCase())));
                    this.showCanReceiveApprovalNotifications = isAssigned(
                        firstOrDefault(roleDetail.filter((rd) => 'canreceiveapprovalnotifications' === rd.val.toLowerCase()))
                    );
                },
                ['roleDetail']
            );
        }
    }

    onContextRoleCdChanged($e: any) {
        if (this.isNewRole) {
            const crcd = this.contextRoleCd?.split(',');
            const l = crcd?.length ?? 0;

            this.showCanCreateAccounts = false;
            this.showCanReceiveApprovalNotifications = false;

            if (l >= 2) {
                if (l > 0) {
                    this._contextRole.contextCd = crcd![0];
                    this._contextRole.information!.contextCd = crcd![0];
                }
                if (l > 1) {
                    this._contextRole.roleCd = crcd![1];
                    this._contextRole.information!.roleCd = crcd![1];
                }

                this.onAnyChange($e);

                this.readRoleDetails();

                this.readScopes();
            } else {
                this.newScopes = [];
                this._newScopeFields = [];
            }
        }
    }

    onScopesChanged($e: any) {
        this.translateNewScopes();

        this.onAnyChange($e);
    }
}
