import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ColumnViewDef } from '../../view';
import { OptionDTO } from '../../dtos';
import { isAssigned } from '../../utils';

@Component({
    selector: 'idb-data-table-filter',
    templateUrl: './data-table-filter.component.html',
    styleUrls: ['./data-table-filter.component.scss'],
})
export class DataTableFilterComponent {
    private _timeout: any = null;
    private _column: ColumnViewDef = { name: '', header: '' };
    private _hasDelay = false;
    private _withFilterDelay = false;
    private _filterDelay = 1500;

    constructor() {}

    private checlHasDelay() {
        this._hasDelay =
            this._withFilterDelay &&
            this._filterDelay > 0 &&
            isAssigned(this._column) &&
            isAssigned(this._column.isFilter) &&
            (this._column.isFilter === 0 || this._column.isFilter === 1);
    }

    @Input()
    filterByLabel = 'Filter by';

    @Input()
    readonly = false;

    @Input()
    disabled = false;

    @Input()
    set withFilterDelay(value: boolean) {
        this._withFilterDelay = value;
        this.checlHasDelay();
    }

    @Input()
    set filterDelay(value: number) {
        this._filterDelay = value;
        this.checlHasDelay();
    }

    @Input()
    set column(value: ColumnViewDef) {
        this._column = value;
        this.checlHasDelay();
    }
    get column(): ColumnViewDef {
        return this._column;
    }

    @Input()
    filter: any = undefined;

    @Output()
    filterChange = new EventEmitter<any>();

    get filterOptions(): OptionDTO[] {
        if (isAssigned(this.column) && this.column.isFilter === 2 && isAssigned(this.column.filterOptions)) return this.column.filterOptions!() ?? [];

        return [];
    }

    get filterShowSearch(): boolean {
        if (isAssigned(this.column) && this.column.isFilter === 2 && isAssigned(this.column.filterSearchOnMoreThan))
            return this.filterOptions.length > this.column.filterSearchOnMoreThan!;

        return false;
    }

    get filterByLabelInternal(): string {
        const header = this.column.displayHeader ? this.column.displayHeader!() : this._column.header ?? this._column.name;

        return `${this.filterByLabel} ${header}`;
    }

    isAssigned(value: any | undefined): boolean {
        return isAssigned(value);
    }

    onFilterClear($e: any) {
        this.filter = undefined;
        this.filterChange.emit(this.filter);
    }

    onFilterChange($e: any) {
        this.filter = $e;

        if (this._hasDelay) {
            clearTimeout(this._timeout);
            this._timeout = setTimeout(() => {
                this.filterChange.emit($e);
            }, this._filterDelay);
        } else {
            this.filterChange.emit($e);
        }
    }

    onEnter($e: any) {
        if (this.disabled || this.readonly) {
            return;
        }

        $e.stopPropagation();

        if (this._hasDelay) {
            clearTimeout(this._timeout);
        }

        this.filterChange.emit(this.filter);
    }

    onKeypress($e: KeyboardEvent) {
        if (this.column.isFilter === 0 && isAssigned(this.column.isValidKey) && !this.column.isValidKey!($e.key)) {
            $e.preventDefault();
        }
    }
}
