import { PerformedByRequestDTO } from './performed-by-request-dto';
import { TypeScopeDTO } from './type-scope-dto';

export interface SaveUserContextRoleProfileRequestDTO extends PerformedByRequestDTO {
    userEmail?: string;
    contextCd?: string;
    roleCd?: string;
    contextProfileCd?: string;
    userContextRoleProfileId?: number;
    profileStatus?: string;
    deactivationReason?: string;
    keepInMyWorkbasketTill?: string;
    disableAutomaticUpdates?: boolean;
    profilePermissions?: TypeScopeDTO[];
    isInternal?: boolean;
}
