import { ResponseDTO } from './response-dto';

export interface ResendUserContextRoleRequestResponseDTO extends ResponseDTO {
    requestKey?: string;
}

export interface SaveEmailValidationResponseDTO extends ResponseDTO {
    response?: string;
    languageCd?: string;
}
