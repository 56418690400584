import { UserContextRoleIdDTO } from './user-context-role-id-dto';

export interface UserContextRoleScopeDTO extends UserContextRoleIdDTO {
    userRoleContextScopeId?: number;
    canEditScope?: boolean;
    canDeleteScope?: boolean;
    hasChanged?: boolean;
    isDeleted?: boolean;

    field1Value?: string;
    field2Value?: string;
    field3Value?: string;
    field4Value?: string;
    field5Value?: string;
    field6Value?: string;
    field7Value?: string;
    field8Value?: string;
    field9Value?: string;
    field10Value?: string;
    field11Value?: string;
    field12Value?: string;
    field13Value?: string;
    field14Value?: string;
    field15Value?: string;
    field16Value?: string;
    field17Value?: string;
    field18Value?: string;
    field19Value?: string;
    field20Value?: string;

    field1CanApprove?: boolean;
    field2CanApprove?: boolean;
    field3CanApprove?: boolean;
    field4CanApprove?: boolean;
    field5CanApprove?: boolean;
    field6CanApprove?: boolean;
    field7CanApprove?: boolean;
    field8CanApprove?: boolean;
    field9CanApprove?: boolean;
    field10CanApprove?: boolean;
    field11CanApprove?: boolean;
    field12CanApprove?: boolean;
    field13CanApprove?: boolean;
    field14CanApprove?: boolean;
    field15CanApprove?: boolean;
    field16CanApprove?: boolean;
    field17CanApprove?: boolean;
    field18CanApprove?: boolean;
    field19CanApprove?: boolean;
    field20CanApprove?: boolean;

    field1HasAccessDetails?: boolean;
    field2HasAccessDetails?: boolean;
    field3HasAccessDetails?: boolean;
    field4HasAccessDetails?: boolean;
    field5HasAccessDetails?: boolean;
    field6HasAccessDetails?: boolean;
    field7HasAccessDetails?: boolean;
    field8HasAccessDetails?: boolean;
    field9HasAccessDetails?: boolean;
    field10HasAccessDetails?: boolean;
    field11HasAccessDetails?: boolean;
    field12HasAccessDetails?: boolean;
    field13HasAccessDetails?: boolean;
    field14HasAccessDetails?: boolean;
    field15HasAccessDetails?: boolean;
    field16HasAccessDetails?: boolean;
    field17HasAccessDetails?: boolean;
    field18HasAccessDetails?: boolean;
    field19HasAccessDetails?: boolean;
    field20HasAccessDetails?: boolean;

    field1?: string;
    field2?: string;
    field3?: string;
    field4?: string;
    field5?: string;
    field6?: string;
    field7?: string;
    field8?: string;
    field9?: string;
    field10?: string;
    field11?: string;
    field12?: string;
    field13?: string;
    field14?: string;
    field15?: string;
    field16?: string;
    field17?: string;
    field18?: string;
    field19?: string;
    field20?: string;

    field1Id?: number;
    field2Id?: number;
    field3Id?: number;
    field4Id?: number;
    field5Id?: number;
    field6Id?: number;
    field7Id?: number;
    field8Id?: number;
    field9Id?: number;
    field10Id?: number;
    field11Id?: number;
    field12Id?: number;
    field13Id?: number;
    field14Id?: number;
    field15Id?: number;
    field16Id?: number;
    field17Id?: number;
    field18Id?: number;
    field19Id?: number;
    field20Id?: number;

    filter1?: string;
    filter2?: string;
    filter3?: string;
    filter4?: string;
    filter5?: string;
    filter6?: string;
    filter7?: string;
    filter8?: string;
    filter9?: string;
    filter10?: string;
    filter11?: string;
    filter12?: string;
    filter13?: string;
    filter14?: string;
    filter15?: string;
    filter16?: string;
    filter17?: string;
    filter18?: string;
    filter19?: string;
    filter20?: string;
}
