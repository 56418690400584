import { UserContextRoleIdDTO } from './user-context-role-id-dto';

export interface LabelUrlDTO {
    label?: string;
    url?: string;
}

export interface UserContextRoleInformationDTO extends UserContextRoleIdDTO {
    userEmail?: string;
    secondaryEmail?: string;
    contextRoleName?: string;
    contextName?: string;
    roleName?: string;
    roleIsApprover?: boolean;
    roleIsInternal?: boolean;
    roleIsReadOnly?: boolean;
    userFullName?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    preferredLanguageCd?: string;
    primaryPhone?: string;
    mobilePhone?: string;
    fax?: string;
    roleGrantedFromDate?: Date;
    roleGrantedToDate?: Date;
    roleContextStatus?: string;
    roleContextStatusLabel?: string;
    roleAttributes?: string;
    roleAttributeList?: string[];
    idbApps?: string;
    idbAppsList?: LabelUrlDTO[];
    canCreateAccounts?: boolean;
    canReceiveApprovalNotifications?: boolean;
    canEditRole?: boolean;
    canAddScope?: boolean;
    hasScope?: boolean;
    canViewRoleDetails?: boolean;
    canDeleteRole?: boolean;
    canEditRoleInInternalRequestForm?: boolean;
}
