import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr';

import { DialogService } from 'primeng/dynamicdialog';

import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

import { MenuItem, MenuItemCommandEvent } from 'primeng/api';

import { ThemeService } from './theme.service';

import { AccessPermissions, BaseComponent, GlobalsService, LoginAsUserDTO, MetaService, ReadMetaRequestDTO, isAssigned, isEmpty, reloadPage } from './data';
import { showLoginAsUser } from './shared/login-as-user/login-as-user.component';

const TabReports = 'tabreports';
const TabReportsUrl = '/reports';
const TabUserDirectory = 'tabuserdirectory';
const TabUserDirectoryUrl = '/user-directory';
const TabMyWork = 'tabmywork';
const TabMyWorkUrl = '/';

const ComponentName = 'with-menu';
@Component({
    selector: `app-${ComponentName}`,
    templateUrl: './app-with-menu.component.html',
    providers: [DialogService],
    host: {
        class: 'flex flex-column overflow-hidden h-full',
    },
})
export class AppWithMenuComponent extends BaseComponent {
    private _selectedTab = TabMyWork;

    startItems!: MenuItem[];
    endItems!: MenuItem[];
    userConnected!: AccountInfo;
    currentUser?: LoginAsUserDTO;
    isDarkMode: boolean;
    currentUserFT = false;

    constructor(
        private readonly _dialogSrv: DialogService,
        private readonly _globalsSrv: GlobalsService,
        private readonly _authService: MsalService,
        private readonly _themeSrv: ThemeService,
        private readonly _router: Router,
        toastr: ToastrService,
        metaSrv: MetaService,
        location: Location,
        translateSrv: TranslateService
    ) {
        super(toastr, metaSrv, location, translateSrv);
        this._translateKey = `eaps.${ComponentName}`;

        this.isDarkMode = _themeSrv.isDarkMode;

        _globalsSrv.currentUserChange$.subscribe((user) => {
            if (this.currentUserFT) {
                this.doReadMeta();
            } else {
                this.currentUserFT = true;
            }
        });
    }

    protected override onInit(): void {
        super.onInit();

        const url = this._router.url;

        this._selectedTab = url === TabReportsUrl ? TabReports : url === TabUserDirectoryUrl ? TabUserDirectory : TabMyWork;

        this._themeSrv.switchTheme(this.isDarkMode);

        this.setLoginDisplay();

        this.loadMenuItems();
    }

    protected override fillReadMetaRequest(request: ReadMetaRequestDTO): void {
        if (isAssigned(this.userConnected?.idTokenClaims)) {
            request.userEmail = `${this.userConnected.idTokenClaims!['email']}`;
        }
    }

    protected override doReadMeta(): void {
        this.metaSrv.readPermisions().subscribe({
            next: (message) => {
                if (!isEmpty(message)) {
                    this.toastr.error(message);
                } else {
                    if (this.permissions.workTab) {
                        this._selectedTab = TabMyWork;
                        this._router.navigate([TabMyWorkUrl]);
                    } else if (this.permissions.directoryTab) {
                        this._selectedTab = TabUserDirectory;
                        this._router.navigate([TabUserDirectoryUrl]);
                    } else {
                        this._selectedTab = '';
                        this._router.navigate(['/account-information', this._globalsSrv.currentUser?.userEmail, 'info']);
                    }
                }

                this.loadMenuItems();
            },
        });
    }

    private loadMenuItems() {
        this.startItems = [];

        if (this.permissions.workTab) {
            this.startItems.push({
                id: TabMyWork,
                label: this.translate('tabMyWork.label'),
                icon: 'pi pi-fw pi-home',
                command: ($e) => this.onStartMenuClick($e, ['/']),
            });
        }

        if (this.permissions.directoryTab) {
            this.startItems.push({
                id: TabUserDirectory,
                label: this.translate('tabUserDirectory.label'),
                icon: 'pi pi-fw pi-user',
                command: ($e) => this.onStartMenuClick($e, ['/user-directory']),
            });
        }

        if (this.permissions.reportsTab) {
            this.startItems.push({
                id: TabReports,
                label: this.translate('tabReports.label'),
                icon: 'pi pi-fw pi-file',
                command: ($e) => this.onStartMenuClick($e, ['/reports']),
            });
        }

        this.startItems.forEach((it) => {
            it.styleClass = it.id === this._selectedTab ? 'idb-menuitem-active' : 'idb-menuitem-normal';
        });

        this.endItems = [
            this.accountItems,
            this.languagesItems,
            { icon: 'pi pi-fw pi-sun', command: () => this.toggleDarkMode(), visible: this.isDarkMode },
            { icon: 'pi pi-fw pi-moon', command: () => this.toggleDarkMode(), visible: !this.isDarkMode },
        ];
    }

    setLoginDisplay() {
        if (this._authService.instance.getAllAccounts().length > 0) {
            this.userConnected = this._authService.instance.getAllAccounts()[0];

            if (isAssigned(this.userConnected?.idTokenClaims)) {
                const userEmail = `${this.userConnected.idTokenClaims!['email']}`;

                this._globalsSrv.loggedUser = {
                    userEmail: userEmail!,
                    userName: this.userConnected.name!,
                };
                this._globalsSrv.currentUser = this._globalsSrv.loggedUser;
            }
        }
    }

    private setLanguage(lang: string) {
        this._globalsSrv.language = lang;
        this.loadMenuItems();
    }

    get permissions(): AccessPermissions {
        return this._globalsSrv.permissions;
    }

    get languagesItems(): MenuItem {
        const lang = this._globalsSrv.language;
        return {
            label: lang.toUpperCase(),
            items: [
                {
                    label: 'English',
                    disabled: lang === 'en',
                    command: () => this.setLanguage('en'),
                },
                {
                    label: 'Español',
                    disabled: lang === 'es',
                    command: () => this.setLanguage('es'),
                },
                // {
                //     label: 'Português',
                //     disabled: lang === 'pt',
                //     command: () => this.setLanguage('pt'),
                // },
                // {
                //     label: 'Français',
                //     disabled: lang === 'fr',
                //     command: () => this.setLanguage('fr'),
                // },
            ],
        };
    }

    get accountItems(): MenuItem {
        const lang = this._globalsSrv.language;
        const currentUser = this._globalsSrv.currentUser;
        const loggedUser = this._globalsSrv.loggedUser;
        const diffUser = loggedUser?.userEmail.toLowerCase() !== currentUser?.userEmail.toLowerCase();
        let userName = currentUser?.userName;

        if (diffUser) {
            userName = this.translate('accountMenu.label', {
                loggedUser: loggedUser,
                currentUser: currentUser,
            });
        }

        const actItems: MenuItem[] = [
            {
                label: this.translate('myAccount.label'),
                command: () => this._router.navigate(['/account-information', currentUser?.userEmail, 'info']),
            },
        ];

        if (this.permissions.loginAs) {
            actItems.push({
                label: this.translate('loginAs.label'),
                command: () => this.onLoginAsUser(),
            });

            if (diffUser) {
                actItems.push({
                    label: this.translate('returnUser.label', { userName: loggedUser?.userName }),
                    command: () => {
                        this._globalsSrv.currentUser = undefined;
                        const currentUser = this._globalsSrv.currentUser;

                        this.toastr.info(this.translate('loginAs.notification', currentUser));

                        reloadPage(this._router);
                    },
                });
            }
        }

        return {
            label: userName,
            icon: 'pi pi-fw pi-user-edit',
            items: actItems,
        };
    }

    toggleDarkMode() {
        this.isDarkMode = !this.isDarkMode;

        this._themeSrv.switchTheme(this.isDarkMode);
        this.loadMenuItems();
    }

    onStartMenuClick($e: MenuItemCommandEvent, routerLink: any) {
        if (isAssigned($e.item)) {
            this.startItems.forEach((it) => {
                if (it !== $e.item) {
                    it.styleClass = 'idb-menuitem-normal';
                }
            });

            this._selectedTab = $e.item!.id ?? '';
            $e.item!.styleClass = 'idb-menuitem-active';
            this._router.navigate(routerLink);
        }
    }

    onLoginAsUser() {
        showLoginAsUser(this._dialogSrv, (user) => {
            this._globalsSrv.currentUser = user;
            this.toastr.info(this.translate('loginAs.notification', user));

            reloadPage(this._router);
        });
    }
}
