import { RequestDTO } from './request-dto';
import { TypeScopeDTO } from './type-scope-dto';

export interface SaveUserContextRoleExternalRequestRequestDTO extends RequestDTO {
    userEmail?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    secondaryEmail?: string;
    preferredLanguageCd?: string;
    primaryPhone?: string;
    mobilePhone?: string;
    fax?: string;
    roleCd?: string;
    contextCd?: string;
    scopes?: TypeScopeDTO[];
}
