import { UserContextRoleProfilePermissionFieldDTO } from './user-context-role-profile-permission-field-dto';
import { UserContextRoleProfilePermissionValueDTO } from './user-context-role-profile-permission-value-dto';

export interface UserContextRoleProfileStatusDTO {
    profileCd?: string;
    profileName?: string;
    profileStatus?: string;
    profileStatusLabel?: string;
    approverName?: string;
    statusModifiedDate?: Date;
    deniedReason?: string;
    pendingRequestAssignedTillDt?: Date;
    pendingRequestAssignedTillDtLabel?: string;
    pendingAssignedToEmail?: string;
    pendingAssignedToName?: string;
    canEdit?: boolean;
    isAssignedToMe?: boolean;
    disableAutomaticUpdates?: boolean;
    processName?: string;

    fields?: UserContextRoleProfilePermissionFieldDTO[];
    values?: UserContextRoleProfilePermissionValueDTO[];
    exceptions?: UserContextRoleProfilePermissionValueDTO[];
}
