import { RequestDTO } from './request-dto';

export interface ResendUserContextRoleRequestRequestDTO extends RequestDTO {
    userEmail?: string;
    requestKey?: string;
}

export interface SaveEmailValidationRequestDTO extends RequestDTO {
    requestKey?: string;
}
