import { UserContextRoleProfileIdDTO } from './user-context-role-profile-id-dto';

export interface UserAccountInformationDTO {
    userEmail?: string;
    userFullName?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    secondaryEmail?: string;
    accountStatus?: string;
    accountStatusLabel?: string;
    primaryPhone?: string;
    mobilePhone?: string;
    fax?: string;
    preferredLanguageCd?: string;
    objectId?: string;
    deactivationReason?: string;
    country?: string;
    instAcronym?: string;
    idbApps?: string;
    isEmailValidated?: boolean;
    activeProfiles?: string;
    activeProfileIds?: UserContextRoleProfileIdDTO[];
    recertifiedDt?: Date;
    isInternal?: boolean;
    b2cAccountActivationDt?: Date;
    canSyncB2C?: boolean;
    canViewAccountDetails?: boolean;
    missingAccountActivation?: boolean;
}
