import { Location } from '@angular/common';
import { Component } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import {
    ActionDef,
    ActionItemDef,
    AnyKey,
    ColumnViewDef,
    ColumnsViewDef,
    ContextRoleScopeComponentItemDTO,
    ContextRoleService,
    MetaService,
    SaveUserContextRoleProfileRequestDTO,
    TypeScopeDTO,
    UserContextRoleProfilePermissionValueDTO,
    firstOrDefault,
    isAssigned,
    isEmpty,
} from '../../data';
import { UserContextRoleScopeProfileBase } from './user-context-role-scope-profile-base';
import { ContextRoleScopePickerKind, PickerTypeScopeDTO, showScopePicker } from '../../shared/context-role-scope-picker/context-role-scope-picker.component';

const AddExceptionMsg = 'Add Exception';
const RemoveExceptionMsg = 'Remove Exception';

@Component({
    selector: 'app-user-context-role-scope-profile-approved',
    templateUrl: './user-context-role-scope-profile-approved.component.html',
    styleUrls: ['./user-context-role-scope-profile-approved.component.scss'],
    providers: [ConfirmationService, DynamicDialogRef],
})
export class UserContextRoleScopeProfileApprovedComponent extends UserContextRoleScopeProfileBase {
    columns: ColumnsViewDef = new ColumnsViewDef([]);
    checkColumns: ColumnViewDef[] = [];

    private _exceptionsItemActions: ActionItemDef[] = [
        {
            icon: 'pi pi-times',
            styleClass: 'danger',
            tooltip: (element) => this.translate('removeException'),
            isVisible: (element) => element?.canEdit === true,
            run: (element) => {
                this.onRemoveException(element);
            },
        },
    ];
    private _exceptionsFooterActions: ActionDef[] = [
        {
            icon: 'pi pi-plus',
            styleClass: 'success',
            tooltip: () => this.translate('addException'),
            isVisible: () => this.profile.canEdit === true,
            run: () => {
                this.onAddException();
            },
        },
    ];

    permissions: UserContextRoleProfilePermissionValueDTO[] = [];
    exceptions: UserContextRoleProfilePermissionValueDTO[] = [];

    constructor(
        confirmSrv: ConfirmationService,
        dialogSrv: DialogService,
        contextRoleSrv: ContextRoleService,
        dialogRef: DynamicDialogRef,
        dialogCfg: DynamicDialogConfig,
        translateSrv: TranslateService,
        toastr: ToastrService,
        metaSrv: MetaService,
        location: Location
    ) {
        super(confirmSrv, dialogSrv, contextRoleSrv, dialogRef, dialogCfg, translateSrv, toastr, metaSrv, location);

        this._translateKey = `${this._translateKey}.approved`;
    }

    get exceptionsItemActions(): ActionItemDef[] | undefined {
        return this.profile.canEdit ? this._exceptionsItemActions : undefined;
    }

    get exceptionsFooterActions(): ActionDef[] | undefined {
        return this.profile.canEdit ? this._exceptionsFooterActions : undefined;
    }

    get isApproveValidated(): boolean {
        let res = this.permissions.length > 0 ? !isAssigned(this.permissions.find((p) => !isAssigned(p.permissionCd))) : true;

        res = res && (this.exceptions.length > 0 ? !isAssigned(this.exceptions.find((p) => !isAssigned(p.permissionCd))) : true);

        return res;
    }

    override onReadProfilePermissions(): void {
        this.calculatePermissionCds();

        this.permissions = this.profile.values ?? [];
        this.exceptions = this.profile.exceptions ?? [];
        this.generateColumns();
    }

    get defaultPermissionSelected(): string {
        if (this.permissions.length > 0) {
            return this.permissions[0].permissionCd ?? '';
        }

        return '';
    }

    get filterValues(): ContextRoleScopeComponentItemDTO[] {
        const res: ContextRoleScopeComponentItemDTO[] = [];
        const def = firstOrDefault(this.profile.values);

        if (isAssigned(def)) {
            for (const field of this.profile.fields ?? []) {
                if (field.fieldType?.toLowerCase() !== 'text') {
                    break;
                }

                if (!isEmpty(field.fieldMapping)) {
                    const fnkl = field.fieldMapping as AnyKey;
                    const fnkv = `${field.fieldMapping}Value` as AnyKey;
                    const l = (def as any)[fnkl]?.toString();
                    const v = (def as any)[fnkv]?.toString();

                    if (isAssigned(v) && v.toLowerCase() !== 'all') {
                        res.push({ fieldValue: v, fieldDisplayValue: l });
                    } else {
                        break;
                    }
                }
            }
        }

        return res;
    }

    calculatePermissionCds() {
        const switchFields = this.profile.fields?.filter((f) => f.fieldType?.toLowerCase() === 'switch') ?? [];

        this.profile.values?.forEach((pv) => {
            pv.permissionCd = undefined;

            switchFields.forEach((sf) => {
                const fk = sf.fieldMapping as AnyKey;
                const fv = (pv as any)[fk];

                if (!isAssigned(pv.permissionCd) && (fv === 1 || fv === '1')) {
                    pv.permissionCd = sf.permissionCd;
                }
            });
        });

        this.profile.exceptions?.forEach((pe) => {
            pe.permissionCd = undefined;

            switchFields.forEach((sf) => {
                const fk = sf.fieldMapping as AnyKey;
                const fe = (pe as any)[fk];

                if (!isAssigned(pe.permissionCd) && (fe === 1 || fe === '1')) {
                    pe.permissionCd = sf.permissionCd;
                }
            });
        });
    }

    generateColumns() {
        const cols: ColumnViewDef[] = [];
        let fieldN = 1;
        this.checkColumns = [];

        this.profile.fields?.forEach((pp) => {
            const col: ColumnViewDef = {
                name: pp.fieldMapping ?? `field${fieldN}`,
            };

            if (pp.fieldType?.toLowerCase() === 'switch') {
                col.name = pp.permissionCd ?? col.name;
                this.checkColumns.push(col);
            }

            fieldN++;

            col.displayHeader = () => pp.fieldName ?? col.name;
            cols.push(col);
        });

        this.columns = new ColumnsViewDef(cols);
    }

    getPermissionButtonIcon(item: UserContextRoleProfilePermissionValueDTO, column: ColumnViewDef): string {
        if (item.permissionCd?.toLowerCase() === column.name.toLowerCase()) {
            return 'pi pi-check';
        }
        return 'pi';
    }

    getPermissionButtonClass(item: UserContextRoleProfilePermissionValueDTO, column: ColumnViewDef): string {
        let res = 'p-button p-button-outlined idb-action-button';

        if (this.profile.canEdit !== true) {
            res = res + ' idb-not-allowed';
        }

        return res;
    }

    onPermissionButtonClick(item: UserContextRoleProfilePermissionValueDTO, column: ColumnViewDef, isDefault: boolean) {
        item.permissionCd = column.name;

        if (isDefault) {
            this.exceptions.forEach((e) => {
                if (e.permissionCd === item.permissionCd) {
                    e.permissionCd = undefined;
                }
            });
        }
    }

    permissionsToScopes(): TypeScopeDTO[] {
        const textFields = this.profile.fields?.filter((f) => f.fieldType === 'text') ?? [];
        const result: TypeScopeDTO[] = [];

        this.permissions.forEach((p) => {
            const permission: TypeScopeDTO = {
                isActive: true,
                isException: false,
                permissionCd: p.permissionCd,
            };

            textFields.forEach((f) => {
                const fk = `${f.fieldMapping}Value` as AnyKey;

                let v = (p as any)[fk];
                if (v?.toString()?.toLowerCase() === 'all') {
                    v = undefined;
                }

                (permission as any)[fk] = v;
            });

            result.push(permission);
        });

        this.exceptions.forEach((p) => {
            const exception: TypeScopeDTO = {
                isActive: true,
                isException: true,
                permissionCd: p.permissionCd,
            };

            textFields.forEach((f) => {
                const fk = `${f.fieldMapping}Value` as AnyKey;

                let v = (p as any)[fk];
                if (v?.toString()?.toLowerCase() === 'all') {
                    v = undefined;
                }

                (exception as any)[fk] = v;
            });

            result.push(exception);
        });

        return result;
    }

    onAddException() {
        const filterValues = this.filterValues;

        showScopePicker(
            this.dialogSrv,
            ContextRoleScopePickerKind.Affiliation,
            this.permissionsToScopes(),
            this.accountInfo,
            {
                userRoleContextId: (this.contextRole?.userRoleContextId ?? -1) > -1 ? this.contextRole!.userRoleContextId : undefined,
                userRoleContextProfileId: this.userRoleContextProfileId,
                contextCd: this.contextRole?.contextCd ?? this.contextRole?.information?.contextCd ?? '',
                roleCd: this.contextRole?.roleCd ?? this.contextRole?.information?.roleCd ?? '',
                filterValues: filterValues,
            },
            (result) => {
                this.onAddExceptionResults(result);
            }
        );
    }

    onAddExceptionResults(result?: PickerTypeScopeDTO[]) {
        if (isAssigned(result) && (result?.length ?? 0) > 0) {
            const textFields = this.profile.fields?.filter((f) => f.fieldType === 'text') ?? [];

            result?.forEach((r) => {
                const rr: UserContextRoleProfilePermissionValueDTO = {};
                let fi = 1;

                textFields.forEach((f) => {
                    if (fi <= 4 && fi <= textFields.length) {
                        const fk1v = `${f.fieldMapping}Value` as AnyKey;
                        const fk1l = f.fieldMapping as AnyKey;
                        const fk2l = `field${fi}DisplayValue` as AnyKey;
                        const fk2v = `field${fi}Value` as AnyKey;

                        (rr as any)[fk1v] = (r as any)[fk2v];
                        (rr as any)[fk1l] = (r as any)[fk2l];
                    }

                    fi++;
                });

                this.profile.exceptions?.push(rr);
            });
        }
    }

    onRemoveException(exception: UserContextRoleProfilePermissionValueDTO) {
        const index = this.profile.exceptions?.indexOf(exception, 0) ?? -1;
        if (index > -1) {
            this.profile.exceptions?.splice(index, 1);
        }
    }

    onSave() {
        const permissions = this.permissionsToScopes();

        const request: SaveUserContextRoleProfileRequestDTO = {
            profileStatus: 'approved',
            profilePermissions: permissions,
        };

        this.saveProfilePermissions(request);
    }
}
